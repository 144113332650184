import { GET_CONSTANTS } from './actions/template.actions'

const state = {
  BASE_URL : "https://www.jestockemonbateau.fr",
  LARAVEL_BASE_URL: "https://admin.jestockemonbateau.fr",
  SITE_NAME : "JeStockeMonBateau",

  STRIPE_TOKEN_PUBLISHABLE: "pk_live_UDXa5DpyrsEcNSk2EQUnYiXT"
}

const getters = {}

const actions = {
  [GET_CONSTANTS]: ({commit}) => {
    console.log('GET_CONSTANTS - Called')
    commit(GET_CONSTANTS)
  }
}

const mutations = {
  [GET_CONSTANTS]: () => {}
}

export default {
  state,
  getters,
  actions,
  mutations
}
