import 'babel-polyfill'
import Es6Promise from 'es6-promise'
import Buefy from 'buefy'
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/index.js'
import VueMq from 'vue-mq'
import displayOnTouch from './utils/displayOnTouch'
import displayOnMobile from './utils/displayOnMobile'
import * as VueGoogleMaps from "vue2-google-maps"
import Meta from 'vue-meta'
import VueCropper from 'vue-cropper'
import GSignInButton from 'vue-google-signin-button'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'



Es6Promise.polyfill()

Vue.use(GSignInButton)
Vue.use(VueGoogleMaps, {
  load: false
});
Vue.use(Buefy, {
  defaultIconPack: 'fas'
})
Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    mobile: 450,
    tablet: 1050,
    laptop: 1310,
    wizard: 1400,
    desktop: Infinity
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})
Vue.use(VueCropper)

Vue.use(VueFacebookPixel)
Vue.analytics.fbq.init('1832449583522773')

Vue.prototype.$multiwatch = function (props, watcher) {
  var iterator = function(prop) {
    this.$watch(prop, watcher);
  };
  props.forEach(iterator, this);
}
Vue.use(Meta)
// Global mixins
Vue.use(displayOnTouch)
Vue.use(displayOnMobile)
Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
