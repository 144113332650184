import { ADD_BOOKMARK_SPACE, REMOVE_BOOKMARK_SPACE, GET_BOOKMARK_SPACE } from './actions/database.actions'
import router from '@/router/router'
import HTTP from '@/utils/http-constant'

const actions = {
  [ADD_BOOKMARK_SPACE]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(ADD_BOOKMARK_SPACE)
      console.log('Commit state change requested : ADD_BOOKMARK_SPACE - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/bookmark-space/store',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : ADD_BOOKMARK_SPACE - success')
        }else{
          console.log('Commit state change : ADD_BOOKMARK_SPACE - fail ' + msg)
        }
        resolve(resp)
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : ADD_BOOKMARK_SPACE - error')
        reject(err)
      })
    })
  },
  [REMOVE_BOOKMARK_SPACE]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(REMOVE_BOOKMARK_SPACE)
      console.log('Commit state change requested : REMOVE_BOOKMARK_SPACE - loading')
      // API Call
      HTTP({
        method: 'DELETE',
        url: '/api/v1/fr/bookmark-space/destroy',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : REMOVE_BOOKMARK_SPACE - success')
        }else{
          console.log('Commit state change : REMOVE_BOOKMARK_SPACE - fail ' + msg)
        }
        resolve(resp)
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : REMOVE_BOOKMARK_SPACE - error')
        reject(err)
      })
    })
  },
  [GET_BOOKMARK_SPACE]: ({commit}) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_BOOKMARK_SPACE)
      console.log('Commit state change requested : GET_BOOKMARK_SPACE - loading')
      // API Call
      HTTP({
        method: 'GET',
        url: '/api/v1/fr/bookmark-space'
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        if (success) {
          console.log('Commit state change : GET_BOOKMARK_SPACE - success')
          resolve(data)
        }else{
          console.log('Commit state change : GET_BOOKMARK_SPACE - fail ' + msg)
        }
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : GET_BOOKMARK_SPACE - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [ADD_BOOKMARK_SPACE]: () => {},
  [REMOVE_BOOKMARK_SPACE]: () => {},
  [GET_BOOKMARK_SPACE]: () => {}
}

export default {
  actions,
  mutations
}
