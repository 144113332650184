import { ADD_BOAT_REQUEST, ADD_BOAT_SUCCESS, ADD_BOAT_ERROR } from './actions/database.actions'
import HTTP from '@/utils/http-constant'

const actions = {
  [ADD_BOAT_REQUEST]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(ADD_BOAT_REQUEST)
      console.log('Commit state change requested : ADD_BOAT_REQUEST - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/boat/store',
        data: {
          user_id: data.userId,
          boat_type_id: data.boatData.boat_type_id,
          length: data.boatData.length,
          height: data.boatData.height,
          width: data.boatData.width
        }
      })
      .then(resp => {
        const {success, msg, data} = resp.data.response
        // AUTH state change
        if (success) {
          commit(ADD_BOAT_SUCCESS)
          console.log('Commit state change : ADD_BOAT - success - '+msg)
          resolve(resp)
        }else{
          commit(ADD_BOAT_ERROR)
          console.log('Request for auth failed, respond with the err :')
          console.log(msg)
          console.log('Commit state change : ADD_BOAT - fail')
          reject(resp)
        }
      })
      .catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        commit(ADD_BOAT_ERROR)
        console.log('Commit state change : ADD_BOAT - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [ADD_BOAT_REQUEST]: (state) => {},
  [ADD_BOAT_SUCCESS]: (state) => {},
  [ADD_BOAT_ERROR]: (state) => {},
}

export default {
  actions,
  mutations
}