import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/entrance/auth'
import facebookAuth from './modules/entrance/facebookAuth'
import forgotten from './modules/entrance/forgotten'
import googleAuth from './modules/entrance/googleAuth'
import signup from './modules/entrance/signup'
import contact from './modules/landing/contact'
import localisation from './modules/landing/localisation'
import modalEntrance from './modules/landing/modalEntrance'
import simulator from './modules/landing/simulator'
import accessType from './modules/database/accessType'
import accountType from './modules/database/accountType'
import announcement from './modules/database/announcement'
import boat from './modules/database/boat'
import boatType from './modules/database/boatType'
import bookmarkSpace from './modules/database/bookmarkSpace'
import cale from './modules/database/cale'
import comment from './modules/database/comment'
import discussion from './modules/database/discussion'
import locationType from './modules/database/locationType'
import option from './modules/database/option'
import order from './modules/database/order'
import security from './modules/database/security'
import setting from './modules/database/setting'
import spaceType from './modules/database/spaceType'
import user from './modules/database/user'
import userGender from './modules/database/userGender'
import userType from './modules/database/userType'
import mainLoader from './modules/template/mainLoader'
import datepickerLocale from './modules/template/datepickerLocale'
import globalConstant from './modules/template/globalConstant'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    facebookAuth,
    forgotten,
    googleAuth,
    signup,
    contact,
    localisation,
    modalEntrance,
    simulator,
    accessType,
    accountType,
    announcement,
    boat,
    boatType,
    bookmarkSpace,
    cale,
    comment,
    discussion,
    locationType,
    option,
    order,
    security,
    setting,
    spaceType,
    user,
    userGender,
    userType,
    mainLoader,
    datepickerLocale,
    globalConstant
  },
  strict: debug
})
