import { GET_SECURITIES } from './actions/database.actions'
import HTTP from '@/utils/http-constant'

const actions = {
  [GET_SECURITIES]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_SECURITIES)
      console.log('Commit state change requested : GET_SECURITIES - loading')
      // API Call
      HTTP({
        method: 'GET',
        url: '/api/v1/fr/security'
      }).then(resp => {
          // GET_SECURITIES state change
          console.log('Commit state change : GET_SECURITIES - success')
          resolve(resp.data.response.data)
      }).catch(err => {
        console.log('Request for GET_SECURITIES failed, respond with the err ' + err)
        console.log('Commit state change : GET_SECURITIES - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [GET_SECURITIES]: () => {
    //
  }
}

export default {
  actions,
  mutations
}