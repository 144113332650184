import { SIMULATOR_REQUEST, SIMULATOR_ERROR, SIMULATOR_SUCCESS } from './actions/landing.actions'
import router from '@/router/router'
import HTTP from '@/utils/http-constant'

const state = {
  price: 0,
  status: '',
  loading: false,
}

const getters = {
  simulatorStatus: state => state.status,
  simulatorLoading: state => state.loading,
  simulatorPrice: state => state.price
}

const actions = {
  [SIMULATOR_REQUEST]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(SIMULATOR_REQUEST)
      console.log('Commit state change requested : SIMULATOR - loading')
      // API Call
      /*HTTP({
          method: 'POST',
          url: '',
          data: {
            area: data.area,
            zip: data.zip
          }
        })
        .then(resp => {
          setTimeout(() => {
            // Simulator state change
            commit(SIMULATOR_SUCCESS, resp)
            console.log('Commit state change : SIMULATOR - success')
            resolve(resp)
          }, 1 * 1000)
        })
        .catch(err => {
          console.log('Request for simulator failed, respond with the err ' + err)
          commit(SIMULATOR_ERROR, err)
          console.log('Commit state change : SIMULATOR - error')
          reject(err)
        })*/
        // Build fake data for dev
        setTimeout(() => {
          let resp = {
            data: {
              price: 250,
            }
          }
          commit(SIMULATOR_SUCCESS, resp)
          console.log('Commit state change : SIMULATOR - success')
          resolve(resp)
        }, 2 * 1000)
    })
  }
}

const mutations = {
  [SIMULATOR_REQUEST]: (state) => {
    state.status = 'loading'
    state.loading = true
  },
  [SIMULATOR_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.price = resp.data.price
    state.loading = false
  },
  [SIMULATOR_ERROR]: (state) => {
    state.status = 'error'
    state.loading = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
