import { FACEBOOK_AUTH_REQUEST, FACEBOOK_AUTH_ERROR, FACEBOOK_SIGNUP_SUCCESS, FACEBOOK_AUTH_SUCCESS, FACEBOOK_SIGNUP_ERROR, AUTH_SUCCESS } from './actions/entrance.actions'
import HTTP from '@/utils/http-constant'

const state = {
  status: '',
  loading: false,
}

const getters = {
  facebookAuthStatus: state => state.status,
  facebookMainLoading: state => state.loading,
}

const actions = {
  [FACEBOOK_AUTH_REQUEST]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(FACEBOOK_AUTH_REQUEST)
      var dataFacebook = data
      console.log('Commit state change requested : FACEBOOK AUTH - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/user/facebook-user-exist',
        data: {
          email: data.email,
          facebook_id: data.id,
        }
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // AUTH state change
        if (success) {
          localStorage.setItem('is-authenticated', true)
          localStorage.setItem('user-name', data.name)
          localStorage.setItem('user-id', data.user_id)
          localStorage.setItem('token', data.token)
          localStorage.setItem('user-picture', data.profil_picture)
          localStorage.setItem('is-professional', data.is_professional)
          localStorage.setItem('user-type', data.user_type)
          localStorage.setItem('full-feature', 1)
          commit(FACEBOOK_AUTH_SUCCESS, resp)
          console.log('Commit state change : FACEBOOK AUTH - success : '+msg)
          commit(AUTH_SUCCESS)
        }else{
          if (data.email_free) {
            // AUTH state change
            commit(FACEBOOK_SIGNUP_SUCCESS, resp)
            localStorage.setItem('cgu', true)
            localStorage.setItem('newsletter', true)

            localStorage.setItem('emailAddress', dataFacebook.email)
            localStorage.setItem('first_name', dataFacebook.first_name)
            localStorage.setItem('last_name', dataFacebook.last_name)
            localStorage.setItem('facebook_id', dataFacebook.id)
            localStorage.setItem('modalCurrentStep', 2)
            console.log('Commit state change : FACEBOOK SIGNUP - success '+msg)
          }else{
            // AUTH state change
            commit(FACEBOOK_SIGNUP_ERROR, resp)
            console.log('Commit state change : FACEBOOK SIGNUP - error')
          }
            
        }
        resolve(resp)
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        commit(FACEBOOK_AUTH_ERROR, err)
        console.log('Commit state change : AUTH - error')
        localStorage.removeItem('is-authenticated')
        localStorage.removeItem('user-name')
        localStorage.removeItem('user-id')
        localStorage.removeItem('token')
        localStorage.removeItem('user-picture')
        localStorage.removeItem('is-professional')
        localStorage.removeItem('user-type')
        localStorage.removeItem('full-feature')

        localStorage.removeItem('emailAddress')
        localStorage.removeItem('first_name')
        localStorage.removeItem('last_name')
        localStorage.removeItem('facebook_id')
        localStorage.removeItem('cgu')
        localStorage.removeItem('newsletter')
        localStorage.removeItem('modalCurrentStep')
        reject(err)
      })
    })
  }
}

const mutations = {
  [FACEBOOK_AUTH_REQUEST]: (state) => {
    state.status = 'loading'
    state.loading = true
  },
  [FACEBOOK_AUTH_SUCCESS]: (state) => {
    state.status = 'success'
    state.loading = false
  },
  [FACEBOOK_SIGNUP_SUCCESS]: (state) => {
    state.status = 'success'
    state.loading = false
  },
  [FACEBOOK_AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.loading = false
  },
  [FACEBOOK_SIGNUP_ERROR]: (state) => {
    state.status = 'error'
    state.loading = false
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
