import { GET_USER_GENDER } from './actions/database.actions'
import HTTP from '@/utils/http-constant'

const actions = {
  [GET_USER_GENDER]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_USER_GENDER)
      console.log('Commit state change requested : GET_USER_GENDER - loading')
      // API Call
      HTTP({
        method: 'GET',
        url: '/api/v1/fr/user-gender'
      }).then(resp => {
          // GET_USER_GENDER state change
          const {success, msg, data} = resp.data.response
          console.log('Commit state change : GET_USER_GENDER - success')
          resolve(data)
      }).catch(err => {
        console.log('Request for GET_USER_GENDER failed, respond with the err ' + err)
        console.log('Commit state change : GET_USER_GENDER - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [GET_USER_GENDER]: () => {
    //
  }
}

export default {
  actions,
  mutations
}