import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { START_LOADER, STOP_LOADER } from '@/store/modules/template/actions/template.actions'

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }else{
    setTimeout(() => {
      if (to.matched[0].name === "Wizard" || to.matched[0].name === "Account") {
        store.state.showModal = true;
      }
    }, 1000);
    next({ name: 'Landing' })
  }
}

Vue.use(Router)

const router = new Router({
  base: '/',
  mode: 'history',
  scrollBehavior (/*to, from, savedPosition*/) {
    return { x: 0, y: 0 }
  },
  
  routes: [

    /**************************
    *                         *
    *       P U B L I C       *
    *                         *
    **************************/

    { path: '*',
      redirect: '404'
    },
    {
      path: '/404',
      name: '404',
      component: resolve => {
        require(['@/components/template/system/404.vue'], resolve)
      }
    },
    {
      path: '/contact',
      name: 'Contact',
      component: resolve => {
        require(['@/components/template/system/Contact.vue'], resolve)
      }
    },
    {
      path: '/rechercher/location-place-a-sec-:city/:id',
      name: 'Detail',
      component: resolve => {
        require(['@/components/detail/Detail.vue'], resolve)
      }
    },
    {
      path: '/',
      name: 'Landing',
      component: resolve => {
        require(['@/components/landing/Landing.vue'], resolve)
      },
      props: (route) => ({ validationToken: route.query.validation_token, passwordToken: route.query.password_token})
    },
    {
      path: '/stockage-entre-particuliers',
      name: 'Rules',
      component: resolve => {
        require(['@/components/static/StorageRules.vue'], resolve)
      },
    },
    {
      path: '/conditions',
      name: 'Conditions',
      component: resolve => {
        require(['@/components/static/StorageRules.vue'], resolve)
      },
    },
    {
      path: '/comment-ca-marche',
      name: 'Howitworks',
      component: resolve => {
        require(['@/components/static/Howitworks.vue'], resolve)
      },
    },
    {
      path: '/tarifs',
      name: 'Prices',
      component: resolve => {
        require(['@/components/static/Prices.vue'], resolve)
      },
    },
    {
      path: '/assurance',
      name: 'Insurance',
      component: resolve => {
        require(['@/components/static/Insurance.vue'], resolve)
      },
    },
    {
      path: '/presse',
      name: 'Press',
      component: resolve => {
        require(['@/components/static/Press.vue'], resolve)
      },
    },
    {
      path: '/Annulation-et-modification',
      name: 'Cancel',
      component: resolve => {
        require(['@/components/static/Cancel.vue'], resolve)
      },
    },
    {
      path: '/politique-d-utilisation',
      name: 'Legal',
      component: resolve => {
        require(['@/components/static/Legal.vue'], resolve)
      },
      redirect: {
        name: 'Mentionslegales'
      },
      children: [
        {
          path: 'cgv',
          name: 'Cgv',
          component: resolve => {
            require(['@/components/static/legal-folder/Cgv.vue'], resolve)
          }
        },
        {
          path: 'cgu',
          name: 'Cgu',
          component: resolve => {
            require(['@/components/static/legal-folder/Cgu.vue'], resolve)
          }
        },
        {
          path: 'mentions-legales',
          name: 'Mentionslegales',
          component: resolve => {
            require(['@/components/static/legal-folder/Mentionslegales.vue'], resolve)
          }
        },
        {
          path: 'politique-de-confidentialite',
          name: 'Confidentiality',
          component: resolve => {
            require(['@/components/static/legal-folder/Confidentiality.vue'], resolve)
          }
        },
        {
          path: 'politique-de-gestion-des-donnees',
          name: 'DatasPolitics',
          component: resolve => {
            require(['@/components/static/legal-folder/Dataspolitics.vue'], resolve)
          }
        },
      ],
    },
    {
      path: '/rechercher/location-place-a-sec-:city',
      name: 'Researchcity',
      component: resolve => {
        require(['@/components/research/Research.vue'], resolve)
      },
      props: (route) => ({
        city: route.params.city,
        boatTypeId: route.query.boatTypeId,
        length: route.query.length,
        height: route.query.height,
        width: route.query.width,
        beginningDate: route.query.beginningDate,
        endingDate: route.query.endingDate,
        locationTypesId: route.query.locationTypeId,
        spaceTypesId: route.query.spaceTypeId,
        securitiesId: route.query.securityId,
        optionsId: route.query.optionId,
        accountTypesId: route.query.accountTypeId,
      }),
    },
    {
      path: '/rechercher',
      name: 'Research',
      component: resolve => {
        require(['@/components/research/Research.vue'], resolve)
      },
      props: (route) => ({
        boatTypeId: route.query.boatTypeId,
        length: route.query.length,
        height: route.query.height,
        width: route.query.width,
        beginningDate: route.query.beginningDate,
        endingDate: route.query.endingDate,
        locationTypesId: route.query.locationTypeId,
        spaceTypesId: route.query.spaceTypeId,
        securitiesId: route.query.securityId,
        optionsId: route.query.optionId,
        accountTypesId: route.query.accountTypeId,
      }),
    },
    {
      path: '/devenir-bateau-stockeur',
      name: 'LandingStockeur',
      component: resolve => {
        require(['@/components/landing-stockeur/Landing-stockeur.vue'], resolve)
      },
    },

    /**************************
    *                         *
    *      P R I V A T E      *
    *                         *
    **************************/

    {
      path: '/deposer-mon-annonce',
      name: 'Wizard',
      component: resolve => {
        require(['@/components/wizard/Wizard.vue'], resolve)
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/mon-compte',
      name: 'Account',
      component: resolve => {
        require(['@/components/account/Account.vue'], resolve)
      },
      beforeEnter: ifAuthenticated,
      redirect: {
        name: 'Profil'
      },
      children: [
        {
          path: 'mon-profil',
          name: 'Profil',
          component: resolve => {
            require(['@/components/account/account-sections/Profil.vue'], resolve)
          }
        },
        {
          path: 'mes-annonces',
          name: 'Annonces',
          component: resolve => {
            require(['@/components/account/account-sections/Place.vue'], resolve)
          }
        },
        {
          path: 'mes-reservations',
          name: 'Reservations',
          component: resolve => {
            require(['@/components/account/account-sections/Reservations.vue'], resolve)
          }
        },
        {
          path: 'mes-messages',
          name: 'Message',
          component: resolve => {
            require(['@/components/account/account-sections/Message.vue'], resolve)
          }
        },
        {
          path: 'mes-messages/message-:messageId',
          name: 'MessageDetail',
          component: resolve => {
            require(['@/components/account/account-sections/message-detail/Detail.vue'], resolve)
          },
          props: (route) => ({
            messageId: route.params.messageId,
          }),
        },
        {
          path: 'mes-commentaires',
          name: 'Comments',
          component: resolve => {
            require(['@/components/account/account-sections/Comments.vue'], resolve)
          }
        },
        {
          path: 'mes-favoris',
          name: 'Favorite',
          component: resolve => {
            require(['@/components/account/account-sections/Favorite.vue'], resolve)
          }
        },
        {
          path: 'mes-paiements',
          name: 'Payments',
          component: resolve => {
            require(['@/components/account/account-sections/Payments.vue'], resolve)
          }
        },
      ]
    },
  ]
})

router.beforeResolve((to, from, next) => {
  store.dispatch(START_LOADER)
  if (to.name !== "Detail") {
    setTimeout(() => {
      store.dispatch(STOP_LOADER)
      next()
    }, 1 * 1500) // timeout for demo purposes
    next()
  }else{
    next()
  }
})

export default router