// AUTH MODULE
export const SIMULATOR_REQUEST = 'SIMULATOR_REQUEST'
export const SIMULATOR_SUCCESS = 'SIMULATOR_SUCCESS'
export const SIMULATOR_ERROR = 'SIMULATOR_ERROR'
// CONTACT MODULE
export const CONTACT_REQUEST = ' CONTACT_REQUEST'
export const CONTACT_ERROR = ' CONTACT_ERROR'
export const CONTACT_SUCCESS = ' CONTACT_SUCCESS'

export const SET_LOCALISATION = ' SET_LOCALISATION'

export const STATE_MODAL = ' STATE_MODAL'