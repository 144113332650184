import { SET_LOCALISATION } from './actions/landing.actions'

const state = {
  localisation: '',
}

const getters = {}

const actions = {
  [SET_LOCALISATION]: ({commit}, data) => {
    commit(SET_LOCALISATION, data)
  }
}

const mutations = {
  [SET_LOCALISATION]: (state, data) => {
    state.localisation = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
