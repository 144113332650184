import { FORGOTTEN_REQUEST, FORGOTTEN_CHECK, FORGOTTEN_CHECK_SUCCESS, FORGOTTEN_CHANGE } from './actions/entrance.actions'
import router from '@/router/router'
import HTTP from '@/utils/http-constant'

const state = {
  status: '',
  loading: false,
  openModalForgotten: false,
}

const getters = {
  openModalForgotten: state => state.openModalForgotten,
}

const actions = {
  [FORGOTTEN_REQUEST]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(FORGOTTEN_REQUEST)
      console.log('Commit state change requested : FORGOTTEN_REQUEST - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/user/forgotten',
        data: {
          email: data.emailAddress
        }
      })
      .then(resp => {
        const {success, msg, data} = resp.data.response
        // AUTH state change
        if (success) {
          console.log('Commit state change : FORGOTTEN_REQUEST - success - '+msg)
          resolve(resp)
        }else{
          console.log('Request for auth failed, respond with the err ' + msg)
          console.log('Commit state change : FORGOTTEN_REQUEST - fail')
          reject(resp)
        }
      })
      .catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : FORGOTTEN_REQUEST - error')
        reject(err)
      })
    })
  },
  [FORGOTTEN_CHECK]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(FORGOTTEN_CHECK)
      console.log('Commit state change requested : FORGOTTEN_CHECK - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/user/forgotten',
        data: {
          token: data.token
        }
      })
      .then(resp => {
        const {success, msg, data} = resp.data.response
        // AUTH state change
        if (success) {
          commit(FORGOTTEN_CHECK_SUCCESS)
          console.log('Commit state change : FORGOTTEN_CHECK - success - '+msg)
          resolve(resp)
        }else{
          console.log('Request for auth failed, respond with the err :')
          console.log(msg)
          console.log('Commit state change : FORGOTTEN_CHECK - fail')
          reject(resp)
        }
      })
      .catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : FORGOTTEN_CHECK - error')
        reject(err)
      })
    })
  },
  [FORGOTTEN_CHANGE]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(FORGOTTEN_CHANGE)
      console.log('Commit state change requested : FORGOTTEN_CHANGE - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/user/forgotten',
        data: {
          token: data.token,
          password: data.password,
        }
      })
      .then(resp => {
        const {success, msg, data} = resp.data.response
        // AUTH state change
        if (success) {
          console.log('Commit state change : FORGOTTEN_CHANGE - success - '+msg)
          resolve(resp)
        }else{
          console.log('Request for auth failed, respond with the err :')
          console.log(msg)
          console.log('Commit state change : FORGOTTEN_CHANGE - fail')
          reject(resp)
        }
      })
      .catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : FORGOTTEN_CHANGE - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [FORGOTTEN_REQUEST]: (state) => {},
  [FORGOTTEN_CHECK]: (state) => {},
  [FORGOTTEN_CHECK_SUCCESS]: (state) => {
    state.openModalForgotten = true
  },
  [FORGOTTEN_CHANGE]: (state) => {},
}

export default {
  state,
  getters,
  actions,
  mutations
}
