import { GET_COMMENT_ANNOUNCEMENT, ADD_COMMENT, GET_COMMENT_ACCOUNT, REMOVE_COMMENT } from './actions/database.actions'
import HTTP from '@/utils/http-constant'

const actions = {
  [GET_COMMENT_ANNOUNCEMENT]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_COMMENT_ANNOUNCEMENT)
      console.log('Commit state change requested : GET_COMMENT_ANNOUNCEMENT - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/comment/to-user-paginate',
        data: data
      }).then(resp => {
        // GET_COMMENT_ANNOUNCEMENT state change
        console.log('Commit state change : GET_COMMENT_ANNOUNCEMENT - success')
        resolve(resp.data.response.data)
      }).catch(err => {
        console.log('Request for GET_COMMENT_ANNOUNCEMENT failed, respond with the err ' + err)
        console.log('Commit state change : GET_COMMENT_ANNOUNCEMENT - error')
        reject(err)
      })
    })
  },
  [ADD_COMMENT]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(ADD_COMMENT)
      console.log('Commit state change requested : ADD_COMMENT - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/comment/store',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : ADD_COMMENT - success')
        }else{
          console.log('Commit state change : ADD_COMMENT - fail ' + msg)
        }
        resolve(resp)
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : ADD_COMMENT - error')
        reject(err)
      })
    })
  },
  [GET_COMMENT_ACCOUNT]: ({commit}) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_COMMENT_ACCOUNT)
      console.log('Commit state change requested : GET_COMMENT_ACCOUNT - loading')
      // API Call
      HTTP({
        method: 'GET',
        url: '/api/v1/fr/comment/account',
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        if (success) {
          console.log('Commit state change : GET_COMMENT_ACCOUNT - success')
          resolve(data)
        }else{
          console.log('Commit state change : GET_COMMENT_ACCOUNT - fail ' + msg)
        }
      }).catch(err => {
        console.log('Request for GET_COMMENT_ACCOUNT failed, respond with the err ' + err)
        console.log('Commit state change : GET_COMMENT_ACCOUNT - error')
        reject(err)
      })
    })
  },
  [REMOVE_COMMENT]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(REMOVE_COMMENT)
      console.log('Commit state change requested : REMOVE_COMMENT - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/comment/destroy',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : REMOVE_COMMENT - success')
          console.log(data)
        }else{
          console.log('Commit state change : REMOVE_COMMENT - fail ' + msg)
        }
        resolve(resp)
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : REMOVE_COMMENT - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [GET_COMMENT_ANNOUNCEMENT]: () => {},
  [ADD_COMMENT]: () => {},
  [GET_COMMENT_ACCOUNT]: () => {},
  [REMOVE_COMMENT]: () => {},
}

export default {
  actions,
  mutations
}