import { GET_SETTING, GET_NOTIFICATIONS } from './actions/database.actions'
import HTTP from '@/utils/http-constant'

const actions = {
  [GET_SETTING]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_SETTING)
      console.log('Commit state change requested : GET_SETTING - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/setting/show',
        data: data
      }).then(resp => {
          // GET_SETTING state change
          console.log('Commit state change : GET_SETTING - success')
          resolve(resp.data.response.data)
      }).catch(err => {
        console.log('Request for GET_SETTING failed, respond with the err ', err)
        console.log('Commit state change : GET_SETTING - error')
        reject(err)
      })
    })
  },
  [GET_NOTIFICATIONS]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_NOTIFICATIONS)
      console.log('Commit state change requested : GET_NOTIFICATIONS - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/setting/notification',
        data: data
      }).then(resp => {
          // GET_NOTIFICATIONS state change
          console.log('Commit state change : GET_NOTIFICATIONS - success')
          resolve(resp.data.response.data)
      }).catch(err => {
        console.log('Request for GET_NOTIFICATIONS failed, respond with the err ', err)
        console.log('Commit state change : GET_NOTIFICATIONS - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [GET_SETTING]: () => {},
  [GET_NOTIFICATIONS]: () => {}
}

export default {
  actions,
  mutations
}