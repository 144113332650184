import axios from 'axios'

const HTTP = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL, // this.$store.state.globalConstant.LARAVEL_BASE_URL
  // baseURL: "http://localhost:8000",
  baseURL: "https://admin.jestockemonbateau.fr",
  headers: {
    'Authorization': {
      toString () {
        return `Bearer ${localStorage.getItem('token')}`
      }
    }
  }
})

export default HTTP
