
/*****************************************
*                 MIXINS                 *
* This helper is made for responsiveness *
* It returns true/false value depending  *
* of media queries.                      *
******************************************/

const displayOnTouch = {
  computed: {
    displayOnTouch () {
      if (this.$mq === 'tablet' || this.$mq === 'mobile') {
        return true
      }
      return false
    }
  }
}

//- Installing it as a plugin
export default {
  install (Vue, options) {
    Vue.mixin(displayOnTouch)
  }
}
