import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_LOGOUT_SUCCESS, AUTH_CHECK } from './actions/entrance.actions'
import router from '@/router/router'
import HTTP from '@/utils/http-constant'

const state = {
  isAuthenticated: localStorage.getItem('is-authenticated') || false,
  userName: localStorage.getItem('user-name') || '',
  userId: localStorage.getItem('user-id') || '',
  status: '',
  loading: false,
  token: localStorage.getItem('token') || '',
  userPicture: localStorage.getItem('user-picture') || '',
  isProfessional: localStorage.getItem('is-professional') || false,
  userType: localStorage.getItem('user-type') || '',
  fullFeature: localStorage.getItem('full-feature') || false,
}

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  userName: state => state.userName,
  userId: state => state.userId,
  authStatus: state => state.status,
  mainLoading: state => state.loading,
  token: state => state.token,
  userPicture: state => state.userPicture,
  isProfessional: state => state.isProfessional,
  userType: state => state.userType,
  fullFeature: state => state.fullFeature,
}

const actions = {
  [AUTH_REQUEST]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(AUTH_REQUEST)
      console.log('Commit state change requested : AUTH - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/user/login',
        data: {
          email: data.emailAddress,
          password: data.password
        }
      })
      .then(resp => {
        const {success, msg, data} = resp.data.response
        // AUTH state change
        if (success) {
          localStorage.setItem('is-authenticated', true)
          localStorage.setItem('user-name', data.name)
          localStorage.setItem('user-id', data.user_id)
          localStorage.setItem('token', data.token)
          localStorage.setItem('user-picture', data.profil_picture)
          localStorage.setItem('is-professional', data.is_professional)
          localStorage.setItem('user-type', data.user_type)
          localStorage.setItem('full-feature', data.full_feature)

          commit(AUTH_SUCCESS, resp)
          console.log('Commit state change : AUTH - success')
          resolve(resp)
        }else{
          console.log('Request for auth failed, respond with the err ' + msg)
          commit(AUTH_ERROR, resp)
          console.log('Commit state change : AUTH - fail')
          localStorage.clear()
          reject(resp)
        }
      })
      .catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        commit(AUTH_ERROR, err)
        console.log('Commit state change : AUTH - error')
        localStorage.clear()
        reject(err)
      })
    })
  },
  [AUTH_LOGOUT]: ({commit}) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT)
      setTimeout(() => {
        localStorage.clear()
        // AUTH state change
        commit(AUTH_LOGOUT_SUCCESS)
        console.log('Commit state change : LOGOUT - success')
        router.push('/')
        resolve()
      }, 2 * 1000)
    })
  },
  [AUTH_CHECK]: ({commit}) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(AUTH_CHECK)
      console.log('Commit state change requested : AUTH_CHECK - loading')
      // API Call
      HTTP({
        method: 'GET',
        url: '/api/v1/fr/user/is-authenticated'
      })
      .then(resp => {
        const {success, msg, data} = resp.data.response
        // AUTH state change
        if (success) {
          commit(AUTH_SUCCESS, resp)
          localStorage.setItem('token', data)
          console.log('Commit state change : AUTH - success - '+msg)
          resolve(resp)
        }else{
          console.log('Request for auth failed, respond with the err ' + msg)
          localStorage.clear()
          commit(AUTH_LOGOUT, resp)
          console.log('Commit state change : AUTH - fail')
          reject(resp)
        }
      })
      .catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        commit(AUTH_ERROR, err)
        console.log('Commit state change : AUTH - error')
        reject(err)
      })
    })
  },
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
    state.loading = true
  },
  [AUTH_SUCCESS]: (state) => {
    state.isAuthenticated = localStorage.getItem('is-authenticated')
    state.userName = localStorage.getItem('user-name')
    state.userId = localStorage.getItem('user-id')
    state.status = 'success'
    state.loading = false
    state.token = localStorage.getItem('token')
    state.userPicture = localStorage.getItem('user-picture')
    state.isProfessional = localStorage.getItem('is-professional')
    state.userType = localStorage.getItem('user-type')
    state.fullFeature = localStorage.getItem('full-feature')
  },
  [AUTH_ERROR]: (state) => {
    state.isAuthenticated = false
    state.status = 'error'
    state.loading = false
  },
  [AUTH_LOGOUT]: (state) => {
    state.isAuthenticated = false
    state.status = 'loading'
    state.loading = true
  },
  [AUTH_LOGOUT_SUCCESS]: (state) => {
    state.isAuthenticated = false
    state.userName = ''
    state.userId = ''
    state.status = 'success'
    state.loading = false
    state.token = ''
    state.userPicture = ''
    state.isProfessional = false
    state.userType = ''
    state.fullFeature = false
  },
  [AUTH_CHECK]: (state) => {
    state.status = 'loading'
    state.loading = true
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
