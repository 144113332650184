import { GET_DISCUSSION_ACCOUNT, GET_MESSAGE_ACCOUNT, ADD_DISCUSSION, ADD_MESSAGE, REMOVE_DISCUSSION } from './actions/database.actions'
import HTTP from '@/utils/http-constant'

const actions = {
  [GET_DISCUSSION_ACCOUNT]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_DISCUSSION_ACCOUNT)
      console.log('Commit state change requested : GET_DISCUSSION_ACCOUNT - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/discussion',
        data: data
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        if (success) {
          console.log('Commit state change : GET_DISCUSSION_ACCOUNT - success')
          resolve(data)
        }else{
          console.log('Commit state change : GET_DISCUSSION_ACCOUNT - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for GET_DISCUSSION_ACCOUNT failed, respond with the err ', err)
        console.log('Commit state change : GET_DISCUSSION_ACCOUNT - error')
        reject(err)
      })
    })
  },
  [GET_MESSAGE_ACCOUNT]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_MESSAGE_ACCOUNT)
      console.log('Commit state change requested : GET_MESSAGE_ACCOUNT - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/discussion/show',
        data: data
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        if (success) {
          console.log('Commit state change : GET_MESSAGE_ACCOUNT - success')
          resolve(data)
        }else{
          console.log('Commit state change : GET_MESSAGE_ACCOUNT - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for GET_MESSAGE_ACCOUNT failed, respond with the err ', err)
        console.log('Commit state change : GET_MESSAGE_ACCOUNT - error')
        reject(err)
      })
    })
  },
  [ADD_DISCUSSION]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(ADD_DISCUSSION)
      console.log('Commit state change requested : ADD_DISCUSSION - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/discussion/store',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : ADD_DISCUSSION - success')
          resolve(success)
        }else{
          console.log('Commit state change : ADD_DISCUSSION - fail ' + msg)
          reject()
        }
        resolve(resp)
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : ADD_DISCUSSION - error')
        reject(err)
      })
    })
  },
  [ADD_MESSAGE]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(ADD_MESSAGE)
      console.log('Commit state change requested : ADD_MESSAGE - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/message/store',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : ADD_MESSAGE - success')
          resolve(data)
        }else{
          console.log('Commit state change : ADD_MESSAGE - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : ADD_MESSAGE - error')
        reject(err)
      })
    })
  },
  [REMOVE_DISCUSSION]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(REMOVE_DISCUSSION)
      console.log('Commit state change requested : REMOVE_DISCUSSION - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/discussion/destroy',
        data: data,
      }).then(resp => {
        const {success, msg} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : REMOVE_DISCUSSION - success')
          resolve()
        }else{
          console.log('Commit state change : REMOVE_DISCUSSION - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : REMOVE_DISCUSSION - error')
        reject(err)
      })
    })
  },
}

const mutations = {
  [GET_DISCUSSION_ACCOUNT]: () => {},
  [GET_MESSAGE_ACCOUNT]: () => {},
  [ADD_DISCUSSION]: () => {},
  [ADD_MESSAGE]: () => {},
  [REMOVE_DISCUSSION]: () => {}
}

export default {
  actions,
  mutations
}