import { ADD_ANNOUNCEMENT, GET_ANNOUNCEMENT, GET_ANNOUNCEMENT_DETAIL, GET_ANNOUNCEMENT_SIMILAR, GET_ANNOUNCEMENT_ACCOUNT, GET_UNAVAILABILITY, SET_SPACE_FREE, REMOVE_SPACE, UPDATE_SPACE, ADD_SPACE, UPDATE_PICTURE, MAIL_FOR_INFORMATION_USER } from './actions/database.actions'
import router from '@/router/router'
import HTTP from '@/utils/http-constant'

const actions = {
  [ADD_ANNOUNCEMENT]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(ADD_ANNOUNCEMENT)
      console.log('Commit state change requested : ADD_ANNOUNCEMENT - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/announcement/store',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          localStorage.removeItem('stepOne')
          localStorage.removeItem('stepTwo')
          localStorage.removeItem('stepThree')
          localStorage.removeItem('stepFour')
          console.log('Commit state change : ADD_ANNOUNCEMENT - success')
          console.log(data)
        }else{
          console.log('Commit state change : ADD_ANNOUNCEMENT - fail ' + msg)
          // Changing page
          router.push('/deposer-mon-annonce')
        }
        resolve(resp)
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : ADD_ANNOUNCEMENT - error')
        router.push('/deposer-mon-annonce')
        reject(err)
      })
    })
  },
  [GET_ANNOUNCEMENT]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_ANNOUNCEMENT)
      console.log('Commit state change requested : GET_ANNOUNCEMENT - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/announcement',
        data: data
      }).then(resp => {
        // GET_ANNOUNCEMENT state change
        const {success, msg, data} = resp.data.response
        if (success) {
          console.log('Commit state change : GET_ANNOUNCEMENT - success')
          resolve(data)
        }else{
          console.log('Commit state change : GET_ANNOUNCEMENT - fail ' + msg)
          reject(data)
        }
      }).catch(err => {
        console.log('Request for GET_ANNOUNCEMENT failed, respond with the err ' + err)
        console.log('Commit state change : GET_ANNOUNCEMENT - error')
        reject(err)
      })
    })
  },
  [GET_ANNOUNCEMENT_DETAIL]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_ANNOUNCEMENT_DETAIL)
      console.log('Commit state change requested : GET_ANNOUNCEMENT_DETAIL - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/announcement/show',
        data: data
      }).then(resp => {
        // GET_ANNOUNCEMENT_DETAIL state change
        const {success, msg, data} = resp.data.response
        if (success) {
          console.log('Commit state change : GET_ANNOUNCEMENT_DETAIL - success')
          resolve(data)
        }else{
          console.log('Commit state change : GET_ANNOUNCEMENT_DETAIL - fail ' + msg)
          reject(data)
        }
      }).catch(err => {
        console.log('Request for GET_ANNOUNCEMENT_DETAIL failed, respond with the err ' + err)
        console.log('Commit state change : GET_ANNOUNCEMENT_DETAIL - error')
        reject(err)
      })
    })
  },
  [GET_ANNOUNCEMENT_SIMILAR]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_ANNOUNCEMENT_SIMILAR)
      console.log('Commit state change requested : GET_ANNOUNCEMENT_SIMILAR - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/announcement/similar',
        data: data
      }).then(resp => {
        // GET_ANNOUNCEMENT_SIMILAR state change
        const {success, msg, data} = resp.data.response
        if (success) {
          console.log('Commit state change : GET_ANNOUNCEMENT_SIMILAR - success')
          resolve(data)
        }else{
          console.log('Commit state change : GET_ANNOUNCEMENT_SIMILAR - empty ' + msg)
          // reject(data)
        }
      }).catch(err => {
        console.log('Request for GET_ANNOUNCEMENT_SIMILAR failed, respond with the err ' + err)
        console.log('Commit state change : GET_ANNOUNCEMENT_SIMILAR - error')
        reject(err)
      })
    })
  },
  [GET_ANNOUNCEMENT_ACCOUNT]: ({commit}) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_ANNOUNCEMENT_ACCOUNT)
      console.log('Commit state change requested : GET_ANNOUNCEMENT_ACCOUNT - loading')
      // API Call
      HTTP({
        method: 'GET',
        url: '/api/v1/fr/announcement/account'
      }).then(resp => {
        // GET_ANNOUNCEMENT_ACCOUNT state change
        const {success, msg, data} = resp.data.response
        if (success) {
          console.log('Commit state change : GET_ANNOUNCEMENT_ACCOUNT - success')
          resolve(data)
        }else{
          console.log('Commit state change : GET_ANNOUNCEMENT_ACCOUNT - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for GET_ANNOUNCEMENT_ACCOUNT failed, respond with the err ' + err)
        console.log('Commit state change : GET_ANNOUNCEMENT_ACCOUNT - error')
        reject(err)
      })
    })
  },
  [GET_UNAVAILABILITY]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_UNAVAILABILITY)
      console.log('Commit state change requested : GET_UNAVAILABILITY - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/announcement/unavailability',
        data: data
      }).then(resp => {
        // GET_UNAVAILABILITY state change
        const {success, msg, data} = resp.data.response
        if (success) {
          console.log('Commit state change : GET_UNAVAILABILITY_SIMILAR - success')
          let unavailabilities = []
          data.forEach(unavailability => {
            unavailabilities.push(new Date(unavailability))
          });
          resolve(unavailabilities)
        }else{
          console.log('Commit state change : GET_UNAVAILABILITY - empty ' + msg)
          // reject(data)
        }
      }).catch(err => {
        console.log('Request for GET_UNAVAILABILITY failed, respond with the err ' + err)
        console.log('Commit state change : GET_UNAVAILABILITY - error')
        reject(err)
      })
    })
  },
  [SET_SPACE_FREE]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(SET_SPACE_FREE)
      console.log('Commit state change requested : SET_SPACE_FREE - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/space/set-free',
        data: data
      }).then(resp => {
        // SET_SPACE_FREE state change
        const {success, msg, data} = resp.data.response
        if (success) {
          console.log('Commit state change : SET_SPACE_FREE - success')
          resolve(data)
        }else{
          console.log('Commit state change : SET_SPACE_FREE - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for SET_SPACE_FREE failed, respond with the err ', err)
        console.log('Commit state change : SET_SPACE_FREE - error')
        reject(err)
      })
    })
  },
  [REMOVE_SPACE]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(REMOVE_SPACE)
      console.log('Commit state change requested : REMOVE_SPACE - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/space/destroy',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : REMOVE_SPACE - success')
          resolve(data)
        }else{
          console.log('Commit state change : REMOVE_SPACE - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : REMOVE_SPACE - error')
        reject(err)
      })
    })
  },
  [UPDATE_SPACE]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(UPDATE_SPACE)
      console.log('Commit state change requested : UPDATE_SPACE - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/space/update',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : UPDATE_SPACE - success')
          resolve(data)
        }else{
          console.log('Commit state change : UPDATE_SPACE - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : UPDATE_SPACE - error')
        reject(err)
      })
    })
  },
  [ADD_SPACE]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(ADD_SPACE)
      console.log('Commit state change requested : ADD_SPACE - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/space/store',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : ADD_SPACE - success')
          resolve(data)
        }else{
          console.log('Commit state change : ADD_SPACE - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : ADD_SPACE - error')
        reject(err)
      })
    })
  },
  [UPDATE_PICTURE]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(UPDATE_PICTURE)
      console.log('Commit state change requested : UPDATE_PICTURE - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/announcement-picture/update',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : UPDATE_PICTURE - success')
          resolve(data)
        }else{
          console.log('Commit state change : UPDATE_PICTURE - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : UPDATE_PICTURE - error')
        reject(err)
      })
    })
  },
  [MAIL_FOR_INFORMATION_USER]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(MAIL_FOR_INFORMATION_USER)
      console.log('Commit state change requested : MAIL_FOR_INFORMATION_USER - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/announcement/send-mail',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : MAIL_FOR_INFORMATION_USER - success')
          resolve(data)
        }else{
          console.log('Commit state change : MAIL_FOR_INFORMATION_USER - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : MAIL_FOR_INFORMATION_USER - error')
        reject(err)
      })
    })
  },

}

const mutations = {
  [ADD_ANNOUNCEMENT]: () => {},
  [GET_ANNOUNCEMENT]: () => {},
  [GET_ANNOUNCEMENT_DETAIL]: () => {},
  [GET_ANNOUNCEMENT_SIMILAR]: () => {},
  [GET_ANNOUNCEMENT_ACCOUNT]: () => {},
  [GET_UNAVAILABILITY]: () => {},
  [SET_SPACE_FREE]: () => {},
  [REMOVE_SPACE]: () => {},
  [UPDATE_SPACE]: () => {},
  [ADD_SPACE]: () => {},
  [UPDATE_PICTURE]: () => {},
  [MAIL_FOR_INFORMATION_USER]: () => {},
}

export default {
  actions,
  mutations
}
