import { GET_DATEPICKER_FR } from './actions/template.actions'

const state = {
  monthNames : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  dayNames: ["Su", "M", "Tu", "W", "Th", "F", "S"]
}

const getters = {}

const actions = {
  [GET_DATEPICKER_FR]: ({commit}) => {
    console.log('DATEPICKER - Called')
    commit(
      GET_DATEPICKER_FR,
      {
        monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        dayNames: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."]
      }
    )
  }
}

const mutations = {
  [GET_DATEPICKER_FR]: (state, data) => {
    state.monthNames = data.monthNames
    state.dayNames = data.dayNames
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
