import { GOOGLE_AUTH_REQUEST, GOOGLE_AUTH_ERROR, GOOGLE_SIGNUP_SUCCESS, GOOGLE_AUTH_SUCCESS, GOOGLE_SIGNUP_ERROR, AUTH_SUCCESS } from './actions/entrance.actions'
import HTTP from '@/utils/http-constant'

const state = {
  status: '',
  loading: false,
}

const getters = {
  googleAuthStatus: state => state.status,
  googleMainLoading: state => state.loading,
}

const actions = {
  [GOOGLE_AUTH_REQUEST]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GOOGLE_AUTH_REQUEST)
      var dataGoogle = data
      console.log('Commit state change requested : GOOGLE AUTH - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/user/google-user-exist',
        data: {
          email: data.email,
          google_id: data.id,
        }
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // AUTH state change
        if (success) {
          localStorage.setItem('is-authenticated', true)
          localStorage.setItem('user-name', data.name)
          localStorage.setItem('user-id', data.user_id)
          localStorage.setItem('token', data.token)
          localStorage.setItem('user-picture', data.profil_picture)
          localStorage.setItem('is-professional', data.is_professional)
          localStorage.setItem('user-type', data.user_type)
          localStorage.setItem('full-feature', 1)
          commit(GOOGLE_AUTH_SUCCESS, resp)
          console.log('Commit state change : GOOGLE AUTH - success : '+msg)
          commit(AUTH_SUCCESS)
        }else{
          if (data.email_free) {
            // AUTH state change
            commit(GOOGLE_SIGNUP_SUCCESS, resp)
            // Set Image Google Base64
            var img = new Image()
            img.crossOrigin = 'Anonymous'
            img.onload = function(){
              var canvas = document.createElement('CANVAS'),
              ctx = canvas.getContext('2d'), dataURL
              canvas.height = img.height
              canvas.width = img.width
              ctx.drawImage(img, 0, 0)
              dataURL = canvas.toDataURL()
              canvas = null

              localStorage.setItem('google_img', dataURL)
            };
            img.src = dataGoogle.imageUrl;

            localStorage.setItem('cgu', true)
            localStorage.setItem('newsletter', true)

            localStorage.setItem('emailAddress', dataGoogle.email)
            localStorage.setItem('first_name', dataGoogle.first_name)
            localStorage.setItem('last_name', dataGoogle.last_name)
            localStorage.setItem('google_id', dataGoogle.id)
            localStorage.setItem('modalCurrentStep', 2)
            console.log('Commit state change : GOOGLE SIGNUP - success '+msg)
          }else{
            // AUTH state change
            commit(GOOGLE_SIGNUP_ERROR, resp)
            console.log('Commit state change : GOOGLE SIGNUP - error')
          }
            
        }
        resolve(resp)
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        commit(GOOGLE_AUTH_ERROR, err)
        console.log('Commit state change : AUTH - error')
        localStorage.removeItem('is-authenticated')
        localStorage.removeItem('user-name')
        localStorage.removeItem('user-id')
        localStorage.removeItem('token')
        localStorage.removeItem('user-picture')
        localStorage.removeItem('is-professional')
        localStorage.removeItem('user-type')
        localStorage.removeItem('full-feature')

        localStorage.removeItem('emailAddress')
        localStorage.removeItem('first_name')
        localStorage.removeItem('last_name')
        localStorage.removeItem('google_id')
        localStorage.removeItem('cgu')
        localStorage.removeItem('newsletter')
        localStorage.removeItem('modalCurrentStep')
        reject(err)
      })
    })
  }
}

const mutations = {
  [GOOGLE_AUTH_REQUEST]: (state) => {
    state.status = 'loading'
    state.loading = true
  },
  [GOOGLE_AUTH_SUCCESS]: (state) => {
    state.status = 'success'
    state.loading = false
  },
  [GOOGLE_SIGNUP_SUCCESS]: (state) => {
    state.status = 'success'
    state.loading = false
  },
  [GOOGLE_AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.loading = false
  },
  [GOOGLE_SIGNUP_ERROR]: (state) => {
    state.status = 'error'
    state.loading = false
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
