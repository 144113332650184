import { GET_USER, UPDATE_USER } from './actions/database.actions'
import HTTP from '@/utils/http-constant'

const actions = {
  [GET_USER]: ({commit}) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_USER)
      console.log('Commit state change requested : GET_USER - loading')
      // API Call
      HTTP({
        method: 'GET',
        url: '/api/v1/fr/user/show'
      }).then(resp => {
          // GET_USER state change
          const {success, msg, data} = resp.data.response
          console.log('Commit state change : GET_USER - success')
          resolve(data)
      }).catch(err => {
        console.log('Request for GET_USER failed, respond with the err ' + err)
        console.log('Commit state change : GET_USER - error')
        reject(err)
      })
    })
  },
  [UPDATE_USER]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(UPDATE_USER)
      console.log('Commit state change requested : UPDATE_USER - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/user/update',
        data: data,
      }).then(resp => {
        // UPDATE_USER state change
        const {success, msg, data} = resp.data.response
        if (success) {
          console.log('Commit state change : UPDATE_USER - success')
          resolve(data)
        }else{
          console.log('Commit state change : UPDATE_USER - '+msg)
          reject(msg)
        }
      }).catch(err => {
        console.log('Request for UPDATE_USER failed, respond with the err ' + err)
        console.log('Commit state change : UPDATE_USER - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [GET_USER]: () => {},
  [UPDATE_USER]: () => {}
}

export default {
  actions,
  mutations
}