import { SIGNUP_CHECK_MAIL, SIGNUP_REQUEST, SIGNUP_CHECK_USER, SIGNUP_CHECK_USER_SUCCESS, AUTH_SUCCESS } from './actions/entrance.actions'
import router from '@/router/router'
import HTTP from '@/utils/http-constant'

const state = {
  status: '',
  loading: false,
  openModalSignUpDone: false,
}

const getters = {
  openModalSignUpDone: state => state.openModalSignUpDone,
}

const actions = {
  [SIGNUP_CHECK_MAIL]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(SIGNUP_CHECK_MAIL)
      console.log('Commit state change requested : SIGNUP_CHECK_MAIL - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/user/check-mail',
        data: {
          email: data.email
        }
      })
      .then(resp => {
        const {success, msg, data} = resp.data.response
        // AUTH state change
        if (success) {
          localStorage.setItem('emailAddress', data)
          console.log('Commit state change : SIGNUP_CHECK_MAIL - success - '+msg)
          resolve(resp)
        }else{
          console.log('Request for auth failed, respond with the err ' + msg['email'][0])
          console.log('Commit state change : SIGNUP_CHECK_MAIL - fail')
          localStorage.removeItem('is-emailAddress')
          reject(resp)
        }
      })
      .catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : SIGNUP_CHECK_MAIL - error')
        localStorage.removeItem('is-emailAddress')
        reject(err)
      })
    })
  },
  [SIGNUP_REQUEST]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(SIGNUP_REQUEST)
      console.log('Commit state change requested : SIGNUP_REQUEST - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/user/store',
        data: {
          newsletter: data.newsletter,
          email: data.email,
          password: data.password,
          first_name: data.first_name,
          last_name: data.last_name,
          facebook_id: data.facebook_id,
          google_id: data.google_id,
          user_type_id: data.user_type_id,
          account_type_id: data.account_type_id,
          full_feature: 1,
          user_gender_id: data.user_gender_id,
          bio: data.bio,
          profil_picture: data.imgSrc,
          phone: data.phone33
        }
      })
      .then(resp => {
        const {success, msg, data} = resp.data.response
        // AUTH state change
        // if (success) {
        //   console.log('Commit state change : SIGNUP_REQUEST - success - '+msg)
        //   resolve(resp)
        // }else{
        //   console.log('Request for auth failed, respond with the err :')
        //   console.log(msg)
        //   console.log('Commit state change : SIGNUP_REQUEST - fail')
        //   reject(resp)
        // }
        // AUTH state change
        if (success) {
          localStorage.setItem('is-authenticated', true)
          localStorage.setItem('user-name', data.name)
          localStorage.setItem('user-id', data.user_id)
          localStorage.setItem('token', data.token)
          localStorage.setItem('user-picture', data.profil_picture)
          localStorage.setItem('is-professional', data.is_professional)
          localStorage.setItem('user-type', data.user_type)
          localStorage.setItem('full-feature', data.full_feature)
          console.log('Commit state change : SIGNUP_CHECK_USER - success - '+msg)
          commit(AUTH_SUCCESS)
          resolve(resp)
        }else{
          console.log('Request for auth failed, respond with the err :')
          console.log(msg)
          console.log('Commit state change : SIGNUP_CHECK_USER - fail')
          localStorage.removeItem('is-authenticated')
          localStorage.removeItem('user-name')
          localStorage.removeItem('user-id')
          localStorage.removeItem('token')
          localStorage.removeItem('user-picture')
          localStorage.removeItem('is-professional')
          localStorage.removeItem('user-type')
          localStorage.removeItem('full-feature')
          reject(resp)
        }
      })
      .catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : SIGNUP_REQUEST - error')
        reject(err)
      })
    })
  },
  [SIGNUP_CHECK_USER]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(SIGNUP_CHECK_USER)
      console.log('Commit state change requested : SIGNUP_CHECK_USER - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/user/check-user',
        data: {
          token: data.token
        }
      })
      .then(resp => {
        const {success, msg, data} = resp.data.response
        // AUTH state change
        if (success) {
          localStorage.setItem('is-authenticated', true)
          localStorage.setItem('user-name', data.name)
          localStorage.setItem('user-id', data.user_id)
          localStorage.setItem('token', data.token)
          localStorage.setItem('user-picture', data.profil_picture)
          localStorage.setItem('is-professional', data.is_professional)
          localStorage.setItem('user-type', data.user_type)
          localStorage.setItem('full-feature', data.full_feature)
          commit(SIGNUP_CHECK_USER_SUCCESS)
          console.log('Commit state change : SIGNUP_CHECK_USER - success - '+msg)
          commit(AUTH_SUCCESS)
          resolve(resp)
          switch (data.user_type) {
            case 'stockeur':
              router.push({ name: 'Wizard' })
              break;

            case 'skipper':
              router.push({ name: 'Account' })
              break;
          }
        }else{
          console.log('Request for auth failed, respond with the err :')
          console.log(msg)
          console.log('Commit state change : SIGNUP_CHECK_USER - fail')
          localStorage.removeItem('is-authenticated')
          localStorage.removeItem('user-name')
          localStorage.removeItem('user-id')
          localStorage.removeItem('token')
          localStorage.removeItem('user-picture')
          localStorage.removeItem('is-professional')
          localStorage.removeItem('user-type')
          localStorage.removeItem('full-feature')
          reject(resp)
        }
      })
      .catch(err => {
        console.log('Request for auth failed, respond with the err ' + err)
        console.log('Commit state change : SIGNUP_CHECK_USER - error')
        localStorage.removeItem('is-authenticated')
        localStorage.removeItem('user-name')
        localStorage.removeItem('user-id')
        localStorage.removeItem('token')
        localStorage.removeItem('user-picture')
        localStorage.removeItem('is-professional')
        localStorage.removeItem('user-type')
        localStorage.removeItem('full-feature')
        reject(err)
      })
    })
  }
}

const mutations = {
  [SIGNUP_CHECK_MAIL]: () => {},
  [SIGNUP_REQUEST]: () => {},
  [SIGNUP_CHECK_USER]: () => {},
  [SIGNUP_CHECK_USER_SUCCESS]: (state) => {
    state.openModalSignUpDone = true
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
