import { ADD_ORDER, GET_ORDER, SET_ORDER_STATUS, STRIPE_CANCEL_ORDER } from './actions/database.actions'
import HTTP from '@/utils/http-constant'

const actions = {
  [ADD_ORDER]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(ADD_ORDER)
      console.log('Commit state change requested : ADD_ORDER - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/order/store',
        data: data,
      }).then(resp => {
        const {success, msg} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : ADD_ORDER - success')
        }else{
          console.log('Commit state change : ADD_ORDER - fail ' + msg)
        }
        resolve(resp)
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : ADD_ORDER - error')
        reject(err)
      })
    })
  },
  [GET_ORDER]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_ORDER)
      console.log('Commit state change requested : GET_ORDER - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/order',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : GET_ORDER - success')
          resolve(data)
        }else{
          console.log('Commit state change : GET_ORDER - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : GET_ORDER - error')
        reject(err)
      })
    })
  },
  [SET_ORDER_STATUS]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(SET_ORDER_STATUS)
      console.log('Commit state change requested : SET_ORDER_STATUS - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/order/status',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : SET_ORDER_STATUS - success')
          resolve(data)
        }else{
          console.log('Commit state change : SET_ORDER_STATUS - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : SET_ORDER_STATUS - error')
        reject(err)
      })
    })
  },
  [STRIPE_CANCEL_ORDER]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(STRIPE_CANCEL_ORDER)
      console.log('Commit state change requested : STRIPE_CANCEL_ORDER - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/order/stripe-cancel-order',
        data: data,
      }).then(resp => {
        const {success, msg, data} = resp.data.response
        // Get the token in answer
        if (success) {
          console.log('Commit state change : STRIPE_CANCEL_ORDER - success')
          resolve(data)
        }else{
          console.log('Commit state change : STRIPE_CANCEL_ORDER - fail ' + msg)
          reject()
        }
      }).catch(err => {
        console.log('Request for auth failed, respond with the err ', err)
        console.log('Commit state change : STRIPE_CANCEL_ORDER - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [ADD_ORDER]: () => {},
  [GET_ORDER]: () => {},
  [SET_ORDER_STATUS]: () => {},
  [STRIPE_CANCEL_ORDER]: () => {},
}

export default {
  actions,
  mutations
}
