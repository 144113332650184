import { START_LOADER, STOP_LOADER } from './actions/template.actions'

const state = {
    globalLoading: false
}
  
const getters = {
    globalLoading: state => state.globalLoading
}

const actions = {
    [START_LOADER]: ({commit, dispatch}) => {
        commit(START_LOADER)
        console.log('start loader')
    },
    [STOP_LOADER]: ({commit, dispatch}) => {
        commit(STOP_LOADER)
        console.log('stop loader')
    }
}

const mutations = {
  [START_LOADER]: () => {
    state.globalLoading = true
  },
  [STOP_LOADER]: () => {
    state.globalLoading = false
  }
}

export default {
  actions,
  state,
  getters,
  mutations
}