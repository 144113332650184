/**
 * DATABASE MODULE
 */ 
export const GET_ACCOUNT_TYPE = 'GET_ACCOUNT_TYPE'
export const GET_BOAT_TYPE = 'GET_BOAT_TYPE'
export const GET_USER_GENDER = 'GET_USER_GENDER'
export const GET_USER_TYPE = 'GET_USER_TYPE'
export const GET_LOCATION_TYPE = 'GET_LOCATION_TYPE'
export const GET_OPTIONS = 'GET_OPTIONS'
export const GET_SECURITIES = 'GET_SECURITIES'
export const GET_SPACE_TYPE = 'GET_SPACE_TYPE'
export const GET_ACCESS_TYPE = 'GET_ACCESS_TYPE'

// ANNOUNCEMENT
export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT'
export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT'
export const GET_ANNOUNCEMENT_DETAIL = 'GET_ANNOUNCEMENT_DETAIL'
export const GET_ANNOUNCEMENT_SIMILAR = 'GET_ANNOUNCEMENT_SIMILAR'
export const GET_ANNOUNCEMENT_ACCOUNT = 'GET_ANNOUNCEMENT_ACCOUNT'
export const GET_UNAVAILABILITY = 'GET_UNAVAILABILITY'
export const SET_SPACE_FREE = 'SET_SPACE_FREE'
export const REMOVE_SPACE = 'REMOVE_SPACE'
export const UPDATE_SPACE = 'UPDATE_SPACE'
export const ADD_SPACE = 'ADD_SPACE'
export const UPDATE_PICTURE = 'UPDATE_PICTURE'
export const MAIL_FOR_INFORMATION_USER = 'MAIL_FOR_INFORMATION_USER'

// BOAT
export const ADD_BOAT_REQUEST = 'ADD_BOAT_REQUEST'
export const ADD_BOAT_SUCCESS = 'ADD_BOAT_SUCCESS'
export const ADD_BOAT_ERROR = 'ADD_BOAT_ERROR'

// BOOKMARK_SPACE
export const ADD_BOOKMARK_SPACE = 'ADD_BOOKMARK_SPACE'
export const REMOVE_BOOKMARK_SPACE = 'REMOVE_BOOKMARK_SPACE'
export const GET_BOOKMARK_SPACE = 'GET_BOOKMARK_SPACE'

// CALE
export const GET_NEAREST_CALES = 'GET_NEAREST_CALES'
export const GET_CALE = 'GET_CALE'

// COMMENT
export const GET_COMMENT_ANNOUNCEMENT = 'GET_COMMENT_ANNOUNCEMENT'
export const ADD_COMMENT = 'ADD_COMMENT'
export const GET_COMMENT_ACCOUNT = 'GET_COMMENT_ACCOUNT'
export const REMOVE_COMMENT = 'REMOVE_COMMENT'

// DISCUSSION
export const GET_DISCUSSION_ACCOUNT = 'GET_DISCUSSION_ACCOUNT'
export const GET_MESSAGE_ACCOUNT = 'GET_MESSAGE_ACCOUNT'
export const ADD_DISCUSSION = 'ADD_DISCUSSION'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const REMOVE_DISCUSSION = 'REMOVE_DISCUSSION'

// ORDER
export const ADD_ORDER = 'ADD_ORDER'
export const GET_ORDER = 'GET_ORDER'
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS'
export const STRIPE_CANCEL_ORDER = 'STRIPE_CANCEL_ORDER'

// SETTING
export const GET_SETTING = 'GET_SETTING'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'

// USER
export const GET_USER = 'GET_USER'
export const UPDATE_USER = 'UPDATE_USER'
