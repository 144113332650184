import { GET_NEAREST_CALES, GET_CALE } from './actions/database.actions'
import HTTP from '@/utils/http-constant'

const actions = {
  [GET_NEAREST_CALES]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_NEAREST_CALES)
      console.log('Commit state change requested : GET_NEAREST_CALES - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/cale',
        data: data
      }).then(resp => {
          // GET_NEAREST_CALES state change
          console.log('Commit state change : GET_NEAREST_CALES - success')
          resolve(resp.data.response.data)
      }).catch(err => {
        console.log('Request for GET_NEAREST_CALES failed, respond with the err ' + err)
        console.log('Commit state change : GET_NEAREST_CALES - error')
        reject(err)
      })
    })
  },
  [GET_CALE]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_CALE)
      console.log('Commit state change requested : GET_CALE - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/cale/show',
        data: data
      }).then(resp => {
          // GET_CALE state change
          console.log('Commit state change : GET_CALE - success')
          resolve(resp.data.response.data)
      }).catch(err => {
        console.log('Request for GET_CALE failed, respond with the err ' + err)
        console.log('Commit state change : GET_CALE - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [GET_NEAREST_CALES]: () => {},
  [GET_CALE]: () => {}
}

export default {
  actions,
  mutations
}