import { CONTACT_REQUEST, CONTACT_ERROR, CONTACT_SUCCESS } from './actions/landing.actions'
import HTTP from '@/utils/http-constant'

const state = {
  status: '',
  loading: false,
}

const getters = {
  contactStatus: state => state.status,
  contactLoading: state => state.loading,
}

const actions = {
  [CONTACT_REQUEST]: ({commit}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(CONTACT_REQUEST)
      console.log('Commit state change requested : CONTACT - loading')
      // API Call
      HTTP({
        method: 'POST',
        url: '/api/v1/fr/contact-entry/store',
        data: data
      }).then(resp => {
        // CONTACT state change
        commit(CONTACT_SUCCESS, resp)
        console.log('Commit state change : CONTACT - success')
        resolve(resp)
      }).catch(err => {
        console.log('Request for CONTACT failed, respond with the err ' + err)
        commit(CONTACT_ERROR, err)
        console.log('Commit state change : CONTACT - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [CONTACT_REQUEST]: (state) => {
    state.status = 'loading'
    state.loading = true
  },
  [CONTACT_SUCCESS]: (state) => {
    state.status = 'success'
    state.loading = false
  },
  [CONTACT_ERROR]: (state) => {
    state.status = 'error'
    state.loading = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
