import { GET_SPACE_TYPE } from './actions/database.actions'
import HTTP from '@/utils/http-constant'

const actions = {
  [GET_SPACE_TYPE]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      // State change
      commit(GET_SPACE_TYPE)
      console.log('Commit state change requested : GET_SPACE_TYPE - loading')
      // API Call
      HTTP({
        method: 'GET',
        url: '/api/v1/fr/space-type'
      }).then(resp => {
          // GET_SPACE_TYPE state change
          console.log('Commit state change : GET_SPACE_TYPE - success')
          resolve(resp.data.response.data)
      }).catch(err => {
        console.log('Request for GET_SPACE_TYPE failed, respond with the err ' + err)
        console.log('Commit state change : GET_SPACE_TYPE - error')
        reject(err)
      })
    })
  }
}

const mutations = {
  [GET_SPACE_TYPE]: () => {
    //
  }
}

export default {
  actions,
  mutations
}