// AUTH MODULE
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_CHECK = 'AUTH_CHECK'

// FACEBOOK AUTH MODULE
export const FACEBOOK_AUTH_REQUEST = 'FACEBOOK_AUTH_REQUEST'
export const FACEBOOK_AUTH_SUCCESS = 'FACEBOOK_AUTH_SUCCESS'
export const FACEBOOK_AUTH_ERROR = 'FACEBOOK_AUTH_ERROR'
export const FACEBOOK_SIGNUP_SUCCESS = 'FACEBOOK_SIGNUP_SUCCESS'
export const FACEBOOK_SIGNUP_ERROR = 'FACEBOOK_SIGNUP_ERROR'

// GOOGLE AUTH MODULE
export const GOOGLE_AUTH_REQUEST = 'GOOGLE_AUTH_REQUEST'
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS'
export const GOOGLE_AUTH_ERROR = 'GOOGLE_AUTH_ERROR'
export const GOOGLE_SIGNUP_SUCCESS = 'GOOGLE_SIGNUP_SUCCESS'
export const GOOGLE_SIGNUP_ERROR = 'GOOGLE_SIGNUP_ERROR'

// SIGNUP MODULE
export const SIGNUP_CHECK_MAIL = 'SIGNUP_CHECK_MAIL'
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_CHECK_USER = 'SIGNUP_CHECK_USER'
export const SIGNUP_CHECK_USER_SUCCESS = 'SIGNUP_CHECK_USER_SUCCESS'

// FORGOTTEN MODULE
export const FORGOTTEN_REQUEST = 'FORGOTTEN_REQUEST'
export const FORGOTTEN_CHECK = 'FORGOTTEN_CHECK'
export const FORGOTTEN_CHECK_SUCCESS = 'FORGOTTEN_CHECK_SUCCESS'
export const FORGOTTEN_CHANGE = 'FORGOTTEN_CHANGE'