import { STATE_MODAL } from './actions/landing.actions'

const state = {
  state_modal: false,
  show_signup: false,
}

const getters = {
  modalState: state => state.state_modal,
  showSignup: state => state.show_signup,
}

const actions = {
  [STATE_MODAL]: ({commit, dispatch}, data) => {
    return new Promise(() => {
      commit(STATE_MODAL, data)
    })
  }
}

const mutations = {
  [STATE_MODAL]: (state, data) => {
    state.state_modal = data['show_modal']
    if (data['show_signup'] !== undefined) {
      state.show_signup = data['show_signup']
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
